// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="nav-logo">
        <Link to="/"><img src="https://res.cloudinary.com/dm9pjru06/image/upload/v1726877966/logo_bfq6ip.png" alt='logo-lavadoras-ariza' className='nav-logo'/></Link>
      </div>
      <div className={`nav-links ${isOpen ? 'open' : ''}`}>
        <Link className='links' to="/" onClick={() => setIsOpen(false)}>Inicio</Link>
        <Link className='links' to="/sobre-mantenimiento-de-lavadoras-ariza" onClick={() => setIsOpen(false)}>Sobre Nosotros</Link>
        <Link className='links' to="/servicios-de-mantenimiento-lavadoras" onClick={() => setIsOpen(false)}>Servicios</Link>
        <Link className='links' to="/preguntas-frecuentes" onClick={() => setIsOpen(false)}>Preguntas frecuentes</Link>
      </div>
      <div className="nav-hamburger" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
