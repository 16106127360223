import React, { useState } from "react";
import './About.css'

import certified from '../../Assets/svg/certified.svg'
import tech from '../../Assets/svg/tech.svg'
import client from '../../Assets/svg/client.svg'
import { Helmet } from 'react-helmet-async';


import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function About() {

    const testimonials = [
        {
            quote: "Excelente servicio, muy recomendado como dejaron la lavadora!",
            name: "Carlos Perez",
            position: "Soacha, Cundinamarca",
            stars: "★★★★★"
        },
        {
            quote: "Muy puntual el señor jorge. Dejó la lavadora perfecta.",
            name: "Ana Gómez",
            position: "Suba, Bogotá D.C",
            stars: "★★★★★"
        },
        {
            quote: "Muy profesional el servicio, realmente encantado",
            name: "Juan Rodríguez",
            position: "Chapinero, Bogotá D.C",
            stars: "★★★★★"
        },
        {
            quote: "Profesionales y atentos a cada detalle desde que llegaron a hacer el servicio",
            name: "Hernando Rogriguez",
            position: "Barrios Unidos, Bogotá D.C",
            stars: "★★★★★"
        },
        {
            quote: "Al principio no confie en el servicio. Pero todo fue excelente",
            name: "Mateo Sanchez",
            position: "Ciudadela Colsubsidio, Bogotá D.C",
            stars: "★★★★★"
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    return (
        <main>
            <Helmet>
                <title>Mantenimiento y Reparación de Lavadoras en Bogotá | 15 Años de Experiencia</title>
                <meta name="description"
                    content="Servicio técnico profesional de mantenimiento y reparación de lavadoras con 15 años de experiencia en Bogotá. Especialistas en LG, Samsung y Whirlpool. Repuestos originales y garantía en todos nuestros servicios. ⚡ Atención inmediata a domicilio." />
                <meta name="keywords"
                    content="mantenimiento lavadoras bogota, reparacion lavadoras, servicio tecnico lavadoras, tecnicos certificados, repuestos originales lavadoras, garantia lavadoras, mantenimiento lg bogota, reparacion samsung bogota, servicio whirlpool bogota" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://mantenimientolavadorasariza.com/sobre-nosotros" />

                <meta property="og:title" content="Mantenimiento de Lavadoras Ariza | 15 Años Reparando Lavadoras en Bogotá" />
                <meta property="og:description" content="Expertos en mantenimiento y reparación de lavadoras. Servicio técnico certificado, repuestos originales y garantía en todo Bogotá. Más de 15 años respaldando nuestro trabajo." />
                <meta property="og:image" content="https://res.cloudinary.com/dm9pjru06/image/upload/v1726877966/logo_bfq6ip.png" />
                <meta property="og:url" content="https://mantenimientolavadorasariza.com/sobre-nosotros" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Mantenimiento de Lavadoras Ariza | Expertos en Reparación" />
                <meta name="twitter:description" content="15 años de experiencia en mantenimiento de lavadoras. Servicio técnico profesional con garantía y repuestos originales en Bogotá." />
                <meta name="twitter:image" content="https://res.cloudinary.com/dm9pjru06/image/upload/v1726877966/logo_bfq6ip.png" />

                <script type="application/ld+json">
                    {`
            {
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                "name": "Mantenimiento de Lavadoras Ariza",
                "image": "https://res.cloudinary.com/dm9pjru06/image/upload/v1726877966/logo_bfq6ip.png",
                "description": "Empresa especializada en mantenimiento y reparación de lavadoras con más de 15 años de experiencia en Bogotá. Ofrecemos servicio técnico profesional, repuestos originales y garantía en todos nuestros trabajos.",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Carrera 92 #129a 22",
                    "addressLocality": "Bogotá",
                    "addressRegion": "Cundinamarca",
                    "postalCode": "110111",
                    "addressCountry": "CO"
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": 4.7110,
                    "longitude": -74.0721
                },
                "url": "https://mantenimientolavadorasariza.com",
                "telephone": "+573144658752",
                "priceRange": "$",
                "openingHoursSpecification": [
                    {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                        "opens": "08:00",
                        "closes": "20:00"
                    },
                    {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": "Saturday",
                        "opens": "09:00",
                        "closes": "18:00"
                    }
                ],
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "reviewCount": "150"
                }
            }
        `}
                </script>
            </Helmet>
            <div className="cnt_about">
                <h1 className="about-title">Sobre Nuestra empresa de mantenimiento de lavadoras</h1>
                <p className="about-text">Somos una empresa especializada en el mantenimiento y reparación de lavadoras en
                    Bogotá. <strong> Con más de 15 años de experiencia</strong>, ofrecemos soluciones rápidas y efectivas para asegurar el óptimo
                    funcionamiento de tu lavadora. ¡Estamos aquí para ayudarte!</p>
            </div>
            <div class="cnt_payments">
                <img src="https://res.cloudinary.com/dm9pjru06/image/upload/v1726879224/pagos_vxqsoj.webp"
                    alt="payments" class="payments" loading="lazy" />
            </div>
            <div className="cnt_about-info">
                <div className="cnt_card-info">
                    <img src={certified} className="svg-card-info" alt="image-about" />
                    <h2 className="about-info-card-title">Garantía en Nuestro Trabajo</h2>
                    <p className="about-info-card-text">Brindamos garantía sobre el trabajo que se le realiza a la Lavadora. Tanto en repuestos como en mano de obra.</p>
                </div>
                <div className="cnt_card-info">
                    <img src={tech} className="svg-card-info" alt="image-about" />
                    <h2 className="about-info-card-title">Repuestos Originales</h2>
                    <p className="about-info-card-text">Ofrecemos repuestos originales y avalados por el fabricante para que sea <strong>100% compatible</strong> con su Lavadora.</p>
                </div>
                <div className="cnt_card-info">
                    <img src={client} className="svg-card-info" alt="image-about" />
                    <h2 className="about-info-card-title">Atención al Cliente</h2>
                    <p className="about-info-card-text">Nos destacamos por la atención al cliente que brindamos. Todos los canales de atanción dispuestos a ayudarle y servirle.</p>
                </div>
            </div>

            <div className="testimonials-carousel">
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial-item">
                            <p className="quote">"{testimonial.quote}"</p>
                            <h4 className="name">- {testimonial.name}</h4>
                            <p className="position">{testimonial.position}</p>
                            <p className="recomendation">Recomendaciones</p>
                            <p className="stars">{testimonial.stars}</p>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="cnt_maps">
                <h2 className="about-info-card-title">¿Donde nos ubicamos?</h2>
                <iframe className="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.262613728137!2d-74.09285822417056!3d4.724383995250738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f84c31fec6613%3A0x1be3809cf244ec32!2sCra.%2092%20%23129a-22%2C%20Suba%2C%20Rincon%2C%20Bogot%C3%A1!5e0!3m2!1ses-419!2sco!4v1724538236780!5m2!1ses-419!2sco" loading="lazy"></iframe>
            </div>
        </main>
    )
}